<template>
  <div id='out-of-services-screen'>
    <div class="container hungarospa-container">
      <div class="content-section">
        <icon-danger />

        <h1 class="title">{{ $t('pages.outOfServices.title') }}</h1>
        <span class="subtitle">{{ $t('pages.outOfServices.subtitle') }}</span>

        <base-button
          :isLoading="isBtnLoading"
          :text="$t('pages.outOfServices.buttons.reload')"
          @clicked="handleBtnClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapActions } from 'vuex';

import IconDanger from '@/components/shared/elements/icons/IconDanger';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'ServicesDownScreen',
  props: {},
  components: {
    IconDanger,
    BaseButton,
  },
  data: () => ({
    isBtnLoading: false,
  }),
  created() {},
  computed: {},
  methods: {
    ...mapActions({
      getServicesStatus: 'settings/getServicesStatus',
    }),
    handleBtnClick() {
      this.isBtnLoading = true;
      this.getServicesStatus().then(() => {
        this.isBtnLoading = false;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
$contentWidth: 600px;

#out-of-services-screen {
  padding: 85px 0 370px;

  @media screen and (max-width: $breakpointDownXs) {
    padding-bottom: 120px;
  }
}

.icon-danger {
  margin: 0 auto 30px;
  text-align: center;

  &::v-deep svg {
    max-height: 140px;

    @media screen and (max-width: $breakpointDownXs) {
      max-height: 100px;
    }
  }
}

.content-section {
  width: $contentWidth;
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  background-color: $white;
  margin: 0 auto;
  padding: 45px 80px 50px;

  @media screen and (max-width: $breakpointDownSm) {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    padding: 35px;
  }
}

.title,
.subtitle {
  display: block;
  text-align: center;
}

.title {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  color: $paymentFailedColor;
  line-height: 1.2;
  margin: 0 0 20px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 20px;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    font-size: 18px;
  }
}

.subtitle {
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 30px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 13px;
  }
}

.button-base {
  min-width: 200px;
}
</style>
